import React from "react";
import { FaFacebookSquare, FaLink } from "react-icons/fa";

const Footer = () => (
  <footer id="footer">
    <div className="social">
      <a
        href="https://www.facebook.com/groups/420244028368348/"
        target="_blank"
      >
        <FaFacebookSquare />
      </a>
      <a href="https://markowens.com/" target="_blank">
        <FaLink />
      </a>
    </div>
    <p className="copyright">&copy; Hard Money Choices, 2019</p>
    <p style={{ fontSize: "8px", color: "grey", margin: 0 }}>
      Inspiration from HTML5-up
    </p>
  </footer>
);

export default Footer;
