import React from "react";

import Layout from "./Layout";
import Footer from "./Footer";

export default class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: "is-loading"
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: "" });
    }, 100);
  }

  render() {
    return (
      <Layout seoTitle={this.props.seoTitle}>
        <div className={`body ${this.state.loading}`}>
          <div id="wrapper">
            {this.props.children}
            <Footer />
          </div>
          <div id="bg" />
        </div>
      </Layout>
    );
  }
}
