import React from "react";
import PropTypes from "prop-types";

import SEO from "./SEO";
import "../styles/main.scss";

const Layout = ({ seoTitle, children }) => {
  return (
    <React.Fragment>
      <SEO
        title={seoTitle}
        keywords={[
          `Borrow Hard Money`,
          `Hard Money Lenders Maryland`,
          `Hard Money Lenders`,
          `Hard Money Maryland`,
          `Hard Money Choices`,
          `Best Way to Borrow Hard Money`
        ]}
      />
      <div>{children}</div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
